import { regexHelper } from './';

export const formatHalfWidthText = (text) => {
    return text.replace(/[\uff01-\uff5e]/g, (character) => {
        return String.fromCharCode(character.charCodeAt(0) - 0xfee0);
    });
};

export const isNumber = (value) => {
    if (regexHelper.RULE.NUMBER.test(value)) {
        return true;
    }

    return false;
};

export const SALE_TYPE = {
    DAILY: {
        value: 1,
        name: '日替わり',
    },
    WEEKLY: {
        value: 2,
        name: '週間特売',
    },
    WEEKEND: {
        value: 3,
        name: '週末特売',
    },
    MONTHLY: {
        value: 4,
        name: '月間特売',
    },
    ALL: {
        value: 5,
        name: '通年',
    },
    OTHER: {
        value: 6,
        name: 'その他',
    },
};

export const BUSINESS_FORMAT = [
    {
        id: 'businessFormat_0',
        value: '0',
        label: 'GMS・SM',
    },
    {
        id: 'businessFormat_1',
        value: '1',
        label: 'SM(地場)',
    },
    {
        id: 'businessFormat_2',
        value: '2',
        label: 'ディスカウント',
    },
    {
        id: 'businessFormat_3',
        value: '3',
        label: 'ドラッグ',
    },
    {
        id: 'businessFormat_4',
        value: '4',
        label: 'ホームセンター',
    },
    {
        id: 'businessFormat_5',
        value: '5',
        label: '専門店',
    },
    {
        id: 'businessFormat_null',
        value: 'null',
        label: 'その他',
    },
];
export const PRODUCT_CATEGORY_OPTION = [
    {
        id: 'product-category-9',
        value: '9',
        label: 'すべて',
    },
    {
        id: 'product-category-0',
        value: '0',
        label: '定番商品',
    },
    {
        id: 'product-category-1',
        value: '1',
        label: '特別提供品',
    },
    {
        id: 'product-category-2',
        value: '2',
        label: '季節定番',
    },
    {
        id: 'product-category-3',
        value: '3',
        label: '客注',
    },
];

export const STOP_PURCHASE_FLAG = [
    {
        id: 'stop-purchase-0',
        value: '0',
        label: '仕入止めなし',
    },
    {
        id: 'stop-purchase-1',
        value: '1',
        label: '仕入止め中',
    },
];

export const TAX_INCLUDED_TYPE = [
    {
        id: 'tax-included-type-0',
        label: '税抜',
        value: 'excluded',
    },
    {
        id: 'tax-included-type-1',
        label: '税込',
        value: 'included',
    },
];

export const REPORT_FLAG = [
    {
        id: 'reportFlag-1',
        label: '実施済',
        value: '1',
    },
    {
        id: 'reportFlag-0',
        label: '未実施',
        value: '0',
    },
];

export const FRESHNESS_REASON_TYPE = [
    {
        id: 'reasonType-賞味期限間近',
        label: '賞味期限間近',
        value: '賞味期限間近',
    },
    {
        id: 'reasonType-その他',
        label: 'その他',
        value: 'その他',
    },
];

export const FREQUENCY_RANK = [
    {
        id: '0',
        label: '週2回〜',
        value: 0,
    },
    {
        id: '1',
        label: '週1回',
        value: 1,
    },
    {
        id: '2',
        label: '隔週',
        value: 2,
    },
    {
        id: '3',
        label: '月1回',
        value: 3,
    },
];

export const RECEIVER_TYPE = [
    {
        id: 'notification-1',
        label: '30%乖離通知',
        value: 'DISSOCIATED',
    },
    {
        id: 'notification-2',
        label: '近隣店舗通知',
        value: 'NEARBY_STORE',
    },
    {
        id: 'notification-3',
        label: '定点観測登録通知',
        value: 'OBSERVATION',
    },
    {
        id: 'notification-4',
        label: '原価割れ通知',
        value: 'BELOW_COST',
    },
];

export const ORGANIZATION = [
    {
        id: 'organization-1',
        label: '店舗',
        value: '0',
    },
    {
        id: 'organization-2',
        label: '本部',
        value: '1',
    },
    {
        id: 'organization-3',
        label: 'IT本部',
        value: '2',
    },
];

export const AUTHORITY = [
    {
        id: 'authority-1',
        label: 'Admin',
        value: '0',
    },
    {
        id: 'authority-2',
        label: 'Manager',
        value: '1',
    },
    {
        id: 'authority-3',
        label: 'Editor',
        value: '2',
    },
];
