/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailReceiverRequest } from '../models/EmailReceiverRequest';
import type { EmailReceiverResponse } from '../models/EmailReceiverResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmailReceiverControllerService {
    /**
     * @param receiverType
     * @param page
     * @param limit
     * @param sortField
     * @param sortType
     * @returns EmailReceiverResponse OK
     * @throws ApiError
     */
    public static getEmailReceivers(
        receiverType: 'NEARBY_STORE' | 'DISSOCIATED' | 'OBSERVATION' | 'BELOW_COST',
        page: number = 1,
        limit: number = 20,
        sortField?: string,
        sortType?: 'ASC' | 'DESC',
    ): CancelablePromise<EmailReceiverResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/email-receiver',
            query: {
                'receiverType': receiverType,
                'page': page,
                'limit': limit,
                'sortField': sortField,
                'sortType': sortType,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createEmailReceiver(
        requestBody: EmailReceiverRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/email-receiver',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteEmailReceiver(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/email-receiver',
            query: {
                'id': id,
            },
        });
    }
}
