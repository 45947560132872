import { useEffect, useState } from 'react';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
import Modal from '../../common/Modal';
import Input from '../../common/Input';
import { setToastsError, setToastsSuccess, setToastsWarning } from '../../../redux/actions/common';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangeRequestBase from './ChangeRequestBase';
import { getNotConfirmTable } from '../../tables/table';
import { ChangeRequestControllerService } from '../../../services';
import '../../../assets/css/pages/stores/change-request-approve.css';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { setLoader } from '../../../redux/actions/api';
import { clearErrors } from '../../../redux/actions/error';
import { prohibitedValidation } from '../../../utils/helpers/validation';

const ChangeRequestApprove = () => {
    // 承認画面
    const confirmStatus = 'NOT_CONFIRM';

    const history = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [location, dispatch]);

    const storage = useSelector((state) => state.storage);
    const { isFreshness, changeRequestType } = storage;

    const [state, setState] = useState({
        apiResponse: null,
        reload: new Date(),

        authorizerName: '',
        isShowModalApprove: false,
        isCheckApproveName: false,
        isPastStartDate: false,
    });

    const {
        apiResponse,
        reload,

        authorizerName,
        isShowModalApprove,
        isCheckApproveName,
        isPastStartDate,
    } = state;

    const handleOnChangeRemandReason = (id, value) => {
        const data = apiResponse?.data.map((item) => {
            if (item.id === id) {
                item.remandReason = value;
            }
            return item;
        });
        setApiResponse({ ...apiResponse, data });
    };

    const table = getNotConfirmTable(t, handleOnChangeRemandReason, isFreshness);

    const setApiResponse = (response) => {
        setState((prevState) => ({
            ...prevState,
            apiResponse: response,
        }));
    };

    const isPastDate = (startDate) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const inputDate = new Date(startDate);

        return inputDate < today;
    };

    const [formData, setFormData] = useState(null);

    const handleShowModal = () => {
        setState((prevState) => ({
            ...prevState,
            isPastStartDate: false,
        }));
        const requestData = apiResponse?.data
            .filter((item) => item.confirmed || item.remand)
            .map((item) => {
                if (isPastDate(item.salesStartDate)) {
                    setState((prevState) => ({
                        ...prevState,
                        isPastStartDate: true,
                    }));
                }
                return item;
            });

        // 禁止商品チェック
        if (prohibitedValidation(dispatch, requestData)) {
            return;
        }

        if (apiResponse?.data.every((item) => item.confirmed === false && item.remand === false)) {
            // すべてのデータがチェックされていない場合はエラーを表示
            dispatch(setToastsError(t('message.info.notify.confirm.registration')));
        } else {
            setState((prevState) => ({
                ...prevState,
                isShowModalApprove: true,
            }));
        }
    };

    const handleSubmitModalApprove = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const request = {
            changeRequestType: changeRequestType,
            authorizerName: authorizerName,
            changeRequestApproveRequests: apiResponse?.data.map((item) => {
                return {
                    id: item.id,
                    remandFlg: item.remand,
                    remandReason: item.remandReason,
                    prohibited: item.prohibited,
                    lowerLimit: item.lowerLimit,
                };
            }),
        };

        ChangeRequestControllerService.approve(request)
            .then((response) => {
                response.length === 0
                    ? dispatch(setToastsWarning('承認が完了しました。\n連携対象商品はありませんでした。'))
                    : dispatch(setToastsSuccess('承認が完了しました。'));
                setState((prevState) => ({
                    ...prevState,
                    isShowModalApprove: false,
                }));
                history('/change-request/remand');
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, '承認が失敗しました。');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCloseModalApprove = () => {
        setState((prevState) => ({
            ...prevState,
            isShowModalApprove: false,
            authorizerName: '',
        }));
    };

    const handleChangeInput = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
            ...(field === 'authorizerName' && { isCheckApproveName: true }),
        }));
    };

    return (
        <div className="confirm-registration-competitive">
            <div className="okc-inner">
                <ChangeRequestBase
                    table={table}
                    confirmStatus={confirmStatus}
                    apiResponse={apiResponse}
                    setApiResponse={setApiResponse}
                    formData={formData}
                    setFormData={setFormData}
                    reload={reload}
                />
                <div className="d-flex justify-content-end c-slide-top mb-5">
                    <Button
                        className="btn btn--primary btn-block"
                        onClick={handleShowModal}
                        children={<span className="btn-text">{t('button.admit')}</span>}
                    />
                </div>

                <Modal
                    size="sm"
                    isShow={isShowModalApprove}
                    onClose={handleCloseModalApprove}
                    isShowIconClose="true"
                    title={
                        isPastStartDate
                            ? t('confirm_registration_price.modal_confirm_start_date.title')
                            : t('confirm_registration_price.modal_confirm.title')
                    }
                    className="registration-edit-modal"
                    btnList={[
                        {
                            name: isPastStartDate
                                ? t('confirm_registration_price.modal_confirm_start_date.button_ok')
                                : t('confirm_registration_price.modal_confirm.button_ok'),
                            className: 'btn--primary',
                            onClick: () => handleSubmitModalApprove(),
                            disabled: !authorizerName,
                        },
                        {
                            name: isPastStartDate
                                ? t('confirm_registration_price.modal_confirm_start_date.button_return')
                                : t('confirm_registration_price.modal_confirm.button_cancel'),
                            className: 'btn--secondary',
                            onClick: () => handleCloseModalApprove(),
                            disabled: false,
                        },
                    ]}
                >
                    <p className="text-center">
                        {isPastStartDate
                            ? t('confirm_registration_price.modal_confirm_start_date.text_01')
                            : t('confirm_registration_price.modal_confirm.first_content')}
                    </p>
                    <p className="text-center">
                        {isPastStartDate
                            ? t('confirm_registration_price.modal_confirm_start_date.text_02')
                            : t('confirm_registration_price.modal_confirm.second_content')}
                    </p>
                    <p className="text-center">
                        {isPastStartDate
                            ? t('confirm_registration_price.modal_confirm_start_date.text_03')
                            : t('confirm_registration_price.modal_confirm.third_content')}
                    </p>
                    <div className="approval-name">
                        <div>
                            <Input
                                className="form-control form-control--input"
                                name="authorizerName"
                                type="text"
                                autocomplete="off"
                                maxLength="45"
                                placeholder={t('confirm_registration_price.modal_confirm_deleted_csv.approval_name.label')}
                                value={authorizerName}
                                onChange={(value) => handleChangeInput('authorizerName', value)}
                                style={{
                                    border: !authorizerName && isCheckApproveName ? '1px solid #eb2013 !important' : '',
                                    borderColor: !authorizerName && isCheckApproveName ? '#eb2013' : '',
                                }}
                            />
                            {!authorizerName && isCheckApproveName && (
                                <p className="okc-error mt-2 mb-0">
                                    {t('confirm_registration_price.modal_confirm_deleted_csv.approval_name.error_message')}
                                </p>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ChangeRequestApprove;
