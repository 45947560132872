import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/common/Card';
import Label from '../../components/common/Label';
import Input from '../../components/common/Input';
import Pagination from '../../components/common/Pagination';
import '../../assets/css/pages/ITheadquarters/users.css';
import { AdminControllerService } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserOrganizations, setLoader } from '../../redux/actions/api';
import EditTable from '../../components/tables/EditTable';
import { getAdminTable } from '../../components/tables/table';
import apiErrorHandler from '../../api/apiErrorHandler';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import Choice from '../../components/common/Choice';
import { AUTHORITY, ORGANIZATION } from '../../utils/constants/string';
import { setToastsSuccess } from '../../redux/actions/common';
import { setDeleteConfirm, setIsDeleteContinue } from '../../redux/actions/modal';
import { getValueAuthority, getValueOrganization } from '../../utils/helpers/common';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../redux/actions/error';

const DEFAULT_STATE = {
    page: 1,
    limit: 20,
    apiResponse: null,
    keyword: '',
    userOrganization: '',
    isShowModalRegister: false,
    authority: '0',
    organization: '0',
    storeCode: '',
    email: '',
    adminId: '',
};

const UserPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState(DEFAULT_STATE);
    const [isEdit, setIsEdit] = useState(false);

    const { page, limit, apiResponse, keyword, userOrganization, isShowModalRegister, organization, authority, storeCode, email, adminId } =
        state;

    const sort = useSelector((state) => state.sort);

    const constants = useSelector((state) => state.constants);
    const { userOrganizations } = constants;

    const location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [location, dispatch]);

    useEffect(() => {
        if (organization !== '0') {
            handleChangeState('storeCode', '');
        }
    }, organization);

    const handleDeleteClick = (index, value) => {
        setState((prevState) => ({
            ...prevState,
            adminId: value.id,
        }));
        dispatch(setDeleteConfirm(true));
    };

    const modal = useSelector((state) => state.modal);
    const { isDeleteContinue } = modal;

    useEffect(() => {
        if (isDeleteContinue) {
            deleteItem();
            dispatch(setIsDeleteContinue(false));
        }
    }, [isDeleteContinue]);

    const deleteItem = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        AdminControllerService.deleteAdmin(adminId)
            .then(() => {
                getAdmins();
                dispatch(setToastsSuccess('削除しました。'));
            })
            .catch((error) => {
                getAdmins();
                apiErrorHandler(dispatch, error, t('message.error.delete'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleEditClick = (index, value) => {
        setState((prevState) => ({
            ...prevState,
            authority: getValueAuthority(value.authorityName),
            organization: getValueOrganization(value.organizationName),
            storeCode: value.storeCode ?? '',
            email: value.email ?? '',
            isShowModalRegister: true,
            adminId: value.id,
        }));
        setIsEdit(true);
    };

    const table = getAdminTable(t, handleDeleteClick, handleEditClick);

    useEffect(() => {
        dispatch(fetchUserOrganizations());
    }, [dispatch]);

    useEffect(() => {
        getAdmins();
    }, [dispatch, page, sort]);

    const getAdmins = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        AdminControllerService.getAdmins(
            keyword ? keyword : null,
            userOrganization ? userOrganization : null,
            page,
            limit,
            sort.sortField,
            sort.sortType,
        )
            .then((response) => {
                setState((prevState) => ({
                    ...prevState,
                    apiResponse: response,
                }));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handlePageClick = (event) => {
        handleChangeState('page', event.selected + 1);
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = () => {
        handleChangeState('page', 1);
        getAdmins();
    };

    const handleClearForm = () => {
        setState(DEFAULT_STATE);
        getAdmins();
    };

    const handleShowSubmitModal = () => {
        setIsEdit(false);
        setState((prevState) => ({
            ...prevState,
            isShowModalRegister: true,
        }));
    };

    const handleCloseModal = () => {
        setState((prevState) => ({
            ...prevState,
            authority: '0',
            organization: '0',
            storeCode: '',
            email: '',
            isShowModalRegister: false,
        }));
        getAdmins();
    };

    const handleSubmitModal = () => {
        if (!isEdit) {
            handleRegister();
        } else {
            handleEdit();
        }
    };

    const handleRegister = () => {
        const requestBody = {
            authority: authority,
            organization: organization,
            storeCode: storeCode,
            email: email,
        };
        dispatch(setLoader(true));
        dispatch(clearErrors());
        AdminControllerService.registerAdmin(requestBody)
            .then(() => {
                handleCloseModal();
                dispatch(setToastsSuccess('登録しました。'));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.registration'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleEdit = () => {
        const requestBody = {
            authority: authority,
            organization: organization,
            storeCode: storeCode,
            email: email,
        };

        dispatch(setLoader(true));
        dispatch(clearErrors());
        AdminControllerService.updateAdmin(adminId, requestBody)
            .then(() => {
                handleCloseModal();
                dispatch(setToastsSuccess('を更新しました。'));
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="price-request-competitive">
            <div className="d-flex justify-content-end c-slide-top margin-bottom__18">
                <Button
                    id="submit-button"
                    className="btn btn--primary mr-2"
                    onClick={handleShowSubmitModal}
                    children={<span className="btn-text">{t('user.user-registration.button.register')}</span>}
                />
            </div>
            <Card handleSubmit={handleSubmit} handleClear={handleClearForm} toggle={false}>
                <Label type="column" text={t('user.label.choose')} className="col-lg-2 mb-2 mb-lg-4" />
                <div className="col-lg-4 pr-lg-3 mb-4">
                    <Input
                        className="form-control form-control--input form-input--width"
                        id="input-4"
                        type="text"
                        autocomplete="off"
                        maxLength="60"
                        name="keyword"
                        onChange={(value) => handleChangeState('keyword', value)}
                        value={keyword}
                    />
                </div>
                <div className="col-lg-6"></div>
                <Label type="column" text={t('user.label.classification')} className="col-lg-2 mb-2 mb-lg-4 mt-lg-2 align-self-start" />
                <div className="col-lg-10 mb-4 competitive-store__radios">
                    <div className="row m-0 competitive-store__row js-okc-validate">
                        {userOrganizations?.map((option, index) => (
                            <div key={index} className={`form-check custom-radio col-xl-3 col-lg-4 col-md-5`}>
                                <input
                                    className={`${option.label ? 'bases__margin--right4' : ''} form-check-input`}
                                    type="radio"
                                    value={option.value}
                                    name={option.id}
                                    id={option.id}
                                    checked={userOrganization === option.value}
                                    onChange={() => handleChangeState('userOrganization', option.value)}
                                />
                                <label className="form-check-label" htmlFor={option.id}>
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
            <Pagination page={page} pageCount={apiResponse?.totalPage} onPageChange={handlePageClick} />
            <EditTable isHeightTr heads={table.heads} body={table.body} dataTable={apiResponse?.data} dataText={t('user.not.found')} />
            <Modal
                size="xl"
                title={t('user.user-registration.title')}
                isShowIconClose="true"
                isShow={isShowModalRegister}
                onClose={handleCloseModal}
                className="registration-edit-modal"
                btnList={[
                    {
                        name: t('user.user_detail.button_cancel'),
                        className: 'btn--primary',
                        onClick: () => handleCloseModal(),
                    },
                    {
                        name: isEdit ? t('user.button_edit') : t('user.user-registration.button.register'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModal(),
                    },
                ]}
            >
                <div className="okc-card okc-result-form">
                    <Card type="modal_card">
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('user.user-registration.authority')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100 js-okc-validate">
                                    <div className="form-check col-lg-2 pr-lg-3">
                                        <Choice
                                            id="role"
                                            column="col-xl-3 col-lg-4 col-md-5"
                                            type="radio"
                                            data={AUTHORITY}
                                            onChange={(value) => handleChangeState('authority', value[0])}
                                            checked={[authority]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('user.user-registration.classification')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100 js-okc-validate">
                                    <div className="form-check col-lg-2 pr-lg-3">
                                        <Choice
                                            id="organization"
                                            column="col-xl-3 col-lg-4 col-md-5"
                                            type="radio"
                                            data={ORGANIZATION}
                                            onChange={(value) => handleChangeState('organization', value[0])}
                                            checked={[organization]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('user.user-registration.store')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 js-switch-target" data-switch="2">
                                        <Input
                                            className={`${true ? 'form-control--error' : ''}`}
                                            onChange={(value) => {
                                                handleChangeState('storeCode', value);
                                            }}
                                            value={storeCode}
                                            placeholder={t('user.user-registration.store')}
                                            {...(organization !== '0' ? { disabled: true } : {})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row okc-card__row">
                            <div className="col-lg-3 okc-result-form__title notification-form__title">
                                <p>{t('user.user-registration.email')}</p>
                            </div>
                            <div className="col-lg-8 offset-lg-1 d-flex">
                                <div className="row no-gutters w-100">
                                    <div className="col-12 js-switch-target" data-switch="2">
                                        <Input
                                            className={`${true ? 'form-control--error' : ''}`}
                                            onChange={(value) => {
                                                handleChangeState('email', value);
                                            }}
                                            value={email}
                                            placeholder={t('user.user-registration.email')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default UserPage;
