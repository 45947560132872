import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import CardPriceSearch from './CardPriceSearch';
import { setToastsError, setToastsSuccess } from '../../../redux/actions/common';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeRequestCompetitorCreateRequest, ChangeRequestControllerService, PriceValidity } from '../../../services';
import { setLoader } from '../../../redux/actions/api';
import ChangeRequestBase from './ChangeRequestBase';
import { getEdlpTable, getRequestingTable } from '../../tables/table';
import { discountValidation, limitOverValidation, prohibitedValidation, validateFormInput } from '../../../utils/helpers/validation';
import { setDeleteConfirm, setIsContinue, setIsDeleteContinue } from '../../../redux/actions/modal';
import '../../../assets/css/pages/stores/change-request.css';
import '../../../assets/css/pages/stores/change-request-remand.css';
import apiErrorHandler from '../../../api/apiErrorHandler';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../../redux/actions/error';
import ViewTable from '../../tables/ViewTable';
import { checkPriceValidity } from '../../../utils/helpers/formula';

const ChangeRequest = (props) => {
    const { confirmStatus, confirmRequestId } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const storage = useSelector((state) => state.storage);
    const { isCompetitor, isFreshness } = storage;

    const [state, setState] = useState({
        apiResponse: null,
        reload: new Date(),
        isShowModalEdit: false, // 編集モーダル
        isShowModalApplication: false, // 自店定番売価モーダル
        edlpData: null,
        modalTitle: null,
    });

    const { apiResponse, reload, isShowModalEdit, isShowModalApplication, edlpData, modalTitle } = state;

    const modal = useSelector((state) => state.modal);
    const { isContinue, isDeleteContinue } = modal;

    const setApiResponse = (response) => {
        setState((prevState) => ({
            ...prevState,
            apiResponse: response,
        }));
    };

    const [formData, setFormData] = useState(null);

    const handleEditClick = (index, value) => {
        setFormData(value);
        handleChangeState('isShowModalEdit', true);
    };

    const table = getRequestingTable(t, handleEditClick, confirmStatus === 'REQUESTING', isFreshness);
    const edlpTable = getEdlpTable(t, isCompetitor);

    const getSelectedData = () => {
        return apiResponse?.data.filter((item) => item.selected) || [];
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitEditFormData = async () => {
        // 入力値チェック
        if (validateFormInput(dispatch, formData, isFreshness)) return;

        const discountedRequestSellingPrice = parseInt(formData.discountedRequestSellingPrice);
        const requestData = [
            {
                ...formData,
                discountedRequestSellingPrice,
            },
        ];

        // 下限値超過チェック
        if (limitOverValidation(dispatch, requestData)) {
            return;
        }

        // 禁止商品チェック
        if (prohibitedValidation(dispatch, requestData)) {
            return;
        }

        // 乖離を無視する場合があるため予めセットしておく
        setState((prevState) => ({
            ...prevState,
            requestData: requestData,
        }));

        // 乖離チェック
        if (await discountValidation(dispatch, requestData, isCompetitor)) {
            return;
        }

        submitEdit();
    };

    useEffect(() => {
        dispatch(clearErrors());
        if (isContinue) {
            // 乖離モーダル表示後に処理を続行する場合
            submitEdit();
            dispatch(setIsContinue(false));
        }
        if (isDeleteContinue) {
            // 削除確認モーダル表示後に処理を続行する場合
            handleCheckDataDeleteSubmit();
            dispatch(setIsDeleteContinue(false));
        }
    }, [dispatch, isContinue, isDeleteContinue, location]);

    const submitEdit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        const request = isCompetitor
            ? ChangeRequestControllerService.updateChangeRequestCompetitor(formData.id, formData)
            : isFreshness
              ? ChangeRequestControllerService.updateChangeRequestFreshness(formData.id, formData)
              : ChangeRequestControllerService.updateChangeRequestInstruction(formData.id, formData);

        request
            .then(() => {
                dispatch(setToastsSuccess('編集が完了しました。'));
                handleChangeState('isShowModalEdit', false);
                handleChangeState('reload', new Date());
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCheckDataDeleteSubmit = () => {
        dispatch(clearErrors());
        const selectedData = getSelectedData();
        const ids = selectedData.map((item) => item.id);
        ChangeRequestControllerService.deleteChangeRequest(ids)
            .then(() => {
                dispatch(setToastsSuccess('削除が完了しました。'));
                handleChangeState('reload', new Date());
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.delete'));
            })
            .finally(() => {
                handleChangeState('isShowDeleteConfirmModal', false);
            });
    };

    const handleShowSubmitModal = async () => {
        dispatch(clearErrors());
        handleChangeState('modalTitle', null);
        const selectedData = getSelectedData();
        const checkItems = {
            item: selectedData.map((item) => {
                return {
                    id: item.id,
                    lowestPriceType: item.lowestPriceType,
                    lowestSellingPrice: item.lowestSellingPrice,
                    requestSellingPrice: item.requestSellingPrice,
                    effectiveEndDate: item.effectiveEndDate,
                    salesEndDate: item.salesEndDate,
                };
            }),
        };

        const checkedItems = await checkPriceValidity(checkItems);

        if (checkedItems.changeRequestIds.length > 0) {
            const matchingItems = selectedData.filter((item) => checkedItems.changeRequestIds.includes(item.id));

            handleChangeState('modalTitle', '更徳・超目よりも高い金額で終了日が早く指定されています。');
            handleChangeState('isShowModalApplication', true);
            handleChangeState('edlpData', matchingItems);
            return;
        }
        if (edlpValidation(selectedData)) {
            handleChangeState('isShowModalApplication', true);
            return;
        }
        handleSubmit(selectedData);
    };

    const handleSubmitModal = () => {
        dispatch(clearErrors());
        const selectedData = getSelectedData();
        handleSubmit(selectedData);
    };

    const handleSubmit = (selectedData) => {
        if (selectedData.length > 0) {
            const ids = selectedData.map((item) => item.id);
            ChangeRequestControllerService.apply(ids)
                .then(() => {
                    dispatch(setToastsSuccess('申請しました。承認画面よりご確認ください。'));
                    handleChangeState('isShowModalApplication', false);
                    handleChangeState('reload', new Date());
                })
                .catch((error) => {
                    apiErrorHandler(dispatch, error, '申請が失敗しました。');
                });
        } else {
            dispatch(setToastsError('申請するデータを選択してください。'));
        }
    };

    const handleShowDeleteModal = () => {
        const selectedData = getSelectedData();
        if (selectedData.length > 0) {
            dispatch(setDeleteConfirm(true));
        } else {
            dispatch(setToastsError('削除するデータを選択してください。'));
        }
    };

    const edlpValidation = (items) => {
        const edlpItems = items.filter((item) => {
            const edlpPrice = Number(item.discountedEdlpSellingPrice);
            const requestPrice = Number(item.requestSellingPrice);
            return !isNaN(edlpPrice) && !isNaN(requestPrice) && edlpPrice < requestPrice;
        });
        if (edlpItems.length > 0) {
            handleChangeState('edlpData', edlpItems);
            return true;
        }
        return false;
    };

    return (
        <>
            <ChangeRequestBase
                table={table}
                confirmStatus={confirmStatus}
                apiResponse={apiResponse}
                setApiResponse={setApiResponse}
                reload={reload}
                confirmRequestId={confirmRequestId}
            />

            <div className="d-flex justify-content-end c-slide-top mb-5">
                <Button
                    id="submit-button"
                    className="btn btn--primary mr-2"
                    onClick={handleShowSubmitModal}
                    disabled={apiResponse?.data.length === 0}
                    children={<span className="btn-text">{t('price_request.apply_button')}</span>}
                />
                <Button
                    id="delete-button"
                    className="btn btn--primary"
                    onClick={handleShowDeleteModal}
                    disabled={apiResponse?.data.length === 0}
                    children={<span className="btn-text">{t('price_request.delete_button')}</span>}
                />
            </div>
            <Modal
                isShow={isShowModalEdit}
                onClose={() => handleChangeState('isShowModalEdit', false)}
                isShowIconClose="true"
                title={t('card_registration.title')}
                className="registration-edit-modal"
                btnList={[
                    {
                        name: t('button.update'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitEditFormData(),
                        disabled: false,
                    },
                ]}
                children={
                    <CardPriceSearch
                        formData={formData}
                        setFormData={setFormData}
                        taxRate={formData?.taxRate}
                        capacityOfUnit={formData?.capacityOfUnit}
                        quantityOfUnit={formData?.quantityOfUnit}
                        discountedLowestSellingPrice={formData?.discountedLowestSellingPrice}
                    />
                }
            />
            <Modal
                isShow={isShowModalApplication}
                onClose={() => handleChangeState('isShowModalApplication', false)}
                isShowIconClose="true"
                size="xll"
                title={
                    modalTitle
                        ? modalTitle
                        : isCompetitor
                          ? t('price_request.application-modal.title')
                          : t('freshness.price_request.modal.title')
                }
                btnList={[
                    {
                        name: t('button.apply'),
                        className: 'btn--primary',
                        onClick: () => handleSubmitModal(),
                        disabled: false,
                    },
                    {
                        name: t('button.cancel'),
                        className: 'btn--secondary',
                        onClick: () => handleChangeState('isShowModalApplication', false),
                        disabled: false,
                    },
                ]}
                children={
                    <>
                        <p className="text-center">
                            {isCompetitor
                                ? t('price_request.application-modal.body.text01')
                                : t('freshness.price_request.application-modal.body.text01')}
                        </p>
                        <p className="text-center">
                            {isCompetitor
                                ? t('price_request.application-modal.body.text02')
                                : t('freshness.price_request.application-modal.body.text02')}
                        </p>
                        <ViewTable heads={edlpTable.heads} body={edlpTable.body} dataTable={edlpData} />
                    </>
                }
            />
        </>
    );
};

export default ChangeRequest;
