import { Outlet, useLocation } from 'react-router-dom';
import * as routes from '../utils/constants/routes';
import { useSelector } from 'react-redux';
import { AdminRequest, PriceSearchRequest } from '../services';
import BrokenLink from '../pages/broken-link';

const ProtectRoute = () => {
    const location = useLocation();
    const storage = useSelector((state) => state.storage);
    const { organization, changeRequestType, authority, isManager } = storage;

    const isPermitted = () => {
        switch (organization) {
            case AdminRequest.organization.STORE:
                switch (changeRequestType) {
                    case PriceSearchRequest.changeRequestType.COMPETITOR:
                        return isManager
                            ? routes.ALLOW_COMPETITOR_MANAGER.some((route) => route.href === location.pathname)
                            : routes.ALLOW_COMPETITOR_EDITOR.some((route) => route.href === location.pathname);
                    case PriceSearchRequest.changeRequestType.FRESHNESS:
                        return isManager
                            ? routes.ALLOW_FRESHNESS_MANAGER.some((route) => route.href === location.pathname)
                            : routes.ALLOW_FRESHNESS_EDITOR.some((route) => route.href === location.pathname);
                    case PriceSearchRequest.changeRequestType.INSTRUCTION:
                        return isManager
                            ? routes.ALLOW_INSTRUCTION_MANAGER.some((route) => route.href === location.pathname)
                            : routes.ALLOW_INSTRUCTION_EDITOR.some((route) => route.href === location.pathname);
                    default:
                        return false;
                }
            case AdminRequest.organization.HEADQUARTERS:
                return isManager
                    ? routes.ALLOW_HEADQUARTER_ONLY.some((route) => route.href === location.pathname)
                    : routes.ALLOW_HEADQUARTER_ONLY.some((route) => route.href === location.pathname);
            case AdminRequest.organization.IT_HEADQUARTERS:
                switch (authority) {
                    case AdminRequest.authority.ADMIN:
                        return routes.ALLOW_IT_HEADQUARTER_ONLY.some((route) => route.href === location.pathname);
                    case AdminRequest.authority.MANAGER:
                        return routes.ALLOW_IT_HEADQUARTER_ONLY.some((route) => route.href === location.pathname);
                    case AdminRequest.authority.EDITOR:
                        return routes.ALLOW_IT_HEADQUARTER_ONLY.some((route) => route.href === location.pathname);
                    default:
                        return false;
                }
            default:
                return false;
        }
    };

    return isPermitted() ? <Outlet /> : <BrokenLink />;
};

export default ProtectRoute;
