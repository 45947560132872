import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from '../../components/common/Pagination';
import Modal from '../../components/common/Modal';
import Choice from '../../components/common/Choice';
import Card from './../../components/common/Card';
import { FREQUENCY_RANK } from '../../utils/constants/string';
import '../../assets/css/pages/stores/survey-history.css';
import SurveyTable from '../../components/tables/SurveyTable';
import { CompetitorMasterControllerService, SurveyHistoryControllerService } from '../../services';
import { setToastsSuccess } from '../../redux/actions/common';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/actions/api';
import apiErrorHandler from '../../api/apiErrorHandler';
import { useLocation } from 'react-router-dom';
import { clearErrors } from '../../redux/actions/error';

const SurveyHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const [state, setState] = useState({
        pageCount: 1,
        page: 1,
        heads: [],
        body: [],
        isShow: false,
        competitorStoreMasterId: 0,
        competitorName: '',
        systemRank: 0,
        storeRank: 0,
    });

    const { pageCount, page, heads, body, isShow, competitorStoreMasterId, competitorName, systemRank, storeRank } = state;

    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch, location]);

    useEffect(() => {
        getSurveyHistory();
    }, [page]);

    const getSurveyHistory = (pageNumber) => {
        if (pageNumber) {
            handleChangeState('page', pageNumber);
        }
        SurveyHistoryControllerService.getSurveyHistory(page).then((response) => {
            setState((prevState) => ({
                ...prevState,
                pageCount: response.totalPage,
                heads: getHeader(response.departments),
                body: response.data,
            }));
        });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1;
        handleChangeState('page', selectedPage);
    };

    const getHeader = (departments) => {
        const defaultHeader = [{ name: '競合店名' }, { name: '調査頻度' }, { name: '未調査日数' }];
        const divisionHeader = departments.map((department) => {
            return {
                name: department.departmentName,
            };
        });
        return [...defaultHeader, ...divisionHeader];
    };

    const handleChangeState = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleShowModal = (competitorStoreMasterId, competitorName, systemRank, storeRank) => {
        setState((prevState) => ({
            ...prevState,
            isShow: true,
            competitorStoreMasterId: competitorStoreMasterId,
            competitorName: competitorName,
            systemRank: systemRank,
            storeRank: storeRank,
        }));
    };

    const handleCloseModal = () => {
        setState((prevState) => ({
            ...prevState,
            isShow: false,
        }));
    };

    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(clearErrors());
        CompetitorMasterControllerService.updateStoreRank(competitorStoreMasterId, storeRank)
            .then(() => {
                dispatch(setToastsSuccess('設定調査頻度を更新しました。'));
                getSurveyHistory(1);
                handleCloseModal();
            })
            .catch((error) => {
                apiErrorHandler(dispatch, error, t('message.error.update'));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="survey-history">
            <div className="okc-inner">
                <div className="okc-title fade-in">{t('survey_history.competitor_research_history')}</div>
                <div className="okc-table-survey c-slide-top">
                    <div id="content-survey-history" className="okc-table__wrapper table-responsive">
                        <SurveyTable heads={heads} body={body} onClickModal={handleShowModal} />
                    </div>
                </div>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} page={page} />
                <Modal
                    size="xll"
                    isShow={isShow}
                    onClose={handleCloseModal}
                    isShowIconClose="true"
                    title={t('survey-history.update-frequency-modal.header')}
                    style={{
                        minWidth: '1200px !important',
                        width: '90%',
                        display: 'block',
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        bottom: '0',
                        left: '60px',
                        zIndex: '10040',
                        overflow: 'auto',
                        overflowY: 'auto',
                    }}
                    btnList={[
                        {
                            name: t('survey-history.update-frequency-modal.footer.button.update'),
                            className: 'btn--primary',
                            onClick: () => handleSubmit(),
                            disabled: false,
                        },
                        {
                            name: t('survey-history.update-frequency-modal.footer.button.cancel'),
                            className: 'btn--secondary master-edit-modal__submit',
                            onClick: () => handleCloseModal(),
                            disabled: false,
                        },
                    ]}
                >
                    <div className="okc-card okc-result-form">
                        <Card type="modal_card">
                            <div className="row okc-card__row">
                                <div className="col-lg-3 mb-3 okc-result-form__title">
                                    <p>{t('survey-history.update-frequency-modal.competitor-store.label')}</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 mb-3">
                                    <p className="m-0" id="competitorName">
                                        {competitorName}
                                    </p>
                                </div>
                                <div className="col-lg-3 okc-result-form__title">
                                    <p>{t('survey-history.update-frequency-modal.system-rank.label')}</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 flex-column">
                                    <div className="row no-gutters w-100 js-okc-validate">
                                        <Choice
                                            column="col-xl-3 col-lg-4 col-md-5 form-check--disabled"
                                            disabled="disabled"
                                            type="radio"
                                            data={FREQUENCY_RANK}
                                            checked={[systemRank]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row okc-card__row">
                                <div className="col-lg-3 okc-result-form__title">
                                    <p>{t('survey-history.update-frequency-modal.store-rank.label')}</p>
                                </div>
                                <div className="col-lg-8 offset-lg-1 flex-column">
                                    <div className="row no-gutters w-100 js-okc-validate">
                                        <Choice
                                            id="storeRank"
                                            column="col-xl-3 col-lg-4 col-md-5 "
                                            type="radio"
                                            data={FREQUENCY_RANK}
                                            onChange={(value) => handleChangeState('storeRank', Number(value[0]))}
                                            checked={[storeRank]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default SurveyHistory;
