import { useTranslation } from 'react-i18next';
import Button from '../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AdminRequest } from '../services';

const BrokenLink = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const storage = useSelector((state) => state.storage);
    const { organization } = storage;
    const handleBack = () => {
        switch (organization) {
            case AdminRequest.organization.STORE:
                history('/select-menu');
                break;
            case AdminRequest.organization.HEADQUARTERS:
                history('/select-store');
                break;
            case AdminRequest.organization.IT_HEADQUARTERS:
                history('/users');
                break;
            default:
                history('/login');
        }
    };
    return (
        <div className="container-fluid okc">
            <div className="row">
                <div className="col-12 col-lg-6 m-auto">
                    <div className="auth d-flex align-items-center justify-content-center">
                        <div className="auth-inner p-0">
                            <div className="auth__logo auth__logo--error fade-in">
                                <i className="icon-image icon-image--broken-link"></i>
                            </div>
                            <div className="auth__error text-center fade-in">
                                <h3>404</h3>
                                <p style={{ whiteSpace: 'pre' }}>
                                    {t('http.not.found')}
                                    {t('page.base.error.message')}
                                </p>
                                <Button className="btn btn--primary" onClick={handleBack}>
                                    <span className="btn-text">{t('homepage.button')}</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrokenLink;
