/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GrantedAuthority } from './GrantedAuthority';
export type UserPrincipal = {
    uuid?: string;
    accountId?: number;
    username?: string;
    password?: string;
    name?: string;
    email?: string;
    roles?: Array<string>;
    expiredAt?: number;
    storeCode?: string;
    storeName?: string;
    authority?: UserPrincipal.authority;
    organization?: UserPrincipal.organization;
    srcIfNo?: string;
    west?: boolean;
    storeUser?: boolean;
    authorities?: Array<GrantedAuthority>;
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired?: boolean;
    itHeadquarter?: boolean;
    manager?: boolean;
    editor?: boolean;
    admin?: boolean;
    headquarter?: boolean;
    enabled?: boolean;
};
export namespace UserPrincipal {
    export enum authority {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        EDITOR = 'EDITOR',
    }
    export enum organization {
        STORE = 'STORE',
        HEADQUARTERS = 'HEADQUARTERS',
        IT_HEADQUARTERS = 'IT_HEADQUARTERS',
    }
}

