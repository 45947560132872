/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../common/Modal';
import '../../assets/css/pages/stores/price-search.css';
import {
    setCommon,
    setDeleteConfirm,
    setExcelValidation,
    setFiftyDiscount,
    setIsContinue,
    setIsDeleteContinue,
    setLimitOver,
    setNbpbkbn,
    setProhibited,
    setTenDiscount,
    setThirtyDiscount,
} from '../../redux/actions/modal';
import ViewTable from '../tables/ViewTable';
import { getExcelValidationTable, getNbpbkbnTable, getProhibitedTable } from '../tables/table';
import { ChangeRequestControllerService } from '../../services';

const CommonModals = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const storage = useSelector((state) => state.storage);
    const { isFreshness } = storage;

    const modal = useSelector((state) => state.modal);
    const {
        fiftyDiscount, // 50%乖離
        thirtyDiscount, // 30%乖離
        tenDiscount, // 10%乖離
        limitOver, // 下限値超過
        prohibited, // 禁止商品
        nbpbkbn, // PB・留型
        excelValidation, // Excelバリデーション
        deleteConfirm, // 削除確認
        common, // 共通
    } = modal;

    const prohibitedTable = getProhibitedTable(t, isFreshness);
    const nbpbkbnTable = getNbpbkbnTable(t);
    const excelValidationTable = getExcelValidationTable(t);

    return (
        <>
            <Modal
                size="sm"
                isShow={fiftyDiscount}
                onClose={() => dispatch(setFiftyDiscount(false))}
                isShowIconClose="true"
                title={t('modal.fifty_percent_selling_price_modal.title')}
                btnList={[
                    {
                        name: t('modal.fifty_percent_selling_price_modal.button'),
                        className: 'btn--primary',
                        onClick: () => dispatch(setFiftyDiscount(false)),
                        disabled: false,
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('modal.fifty_percent_selling_price_modal.body.text01')}</p>
                        <p className="text-center">{t('modal.fifty_percent_selling_price_modal.body.text02')}</p>
                    </>
                }
            />
            <Modal
                size="sm"
                isShow={thirtyDiscount}
                onClose={() => dispatch(setThirtyDiscount(false))}
                isShowIconClose="true"
                title={t('modal.thirty_percent_selling_price_modal.title')}
                btnList={[
                    {
                        name: t('modal.thirty_percent_selling_price_modal.button.primary'),
                        className: 'btn--primary',
                        onClick: () => {
                            dispatch(setIsContinue(true));
                            dispatch(setThirtyDiscount(false));
                        },
                        disabled: false,
                    },
                    {
                        name: t('modal.thirty_percent_selling_price_modal.button.secondary'),
                        className: 'btn--secondary master-edit-modal__submit',
                        onClick: () => dispatch(setThirtyDiscount(false)),
                        disabled: false,
                    },
                ]}
                children={<p className="text-center">{t('modal.thirty_percent_selling_price_modal.body.text')}</p>}
            />
            <Modal
                size="sm"
                isShow={tenDiscount}
                onClose={() => dispatch(setTenDiscount(false))}
                isShowIconClose="true"
                title={t('modal.ten_percent_selling_price_modal.title')}
                btnList={[
                    {
                        name: t('modal.ten_percent_selling_price_modal.button.registration'),
                        className: 'btn--primary',
                        onClick: () => {
                            dispatch(setIsContinue(true));
                            dispatch(setTenDiscount(false));
                        },
                        disabled: false,
                    },
                    {
                        name: t('modal.ten_percent_selling_price_modal.button.cancel'),
                        className: 'btn--secondary master-edit-modal__submit',
                        onClick: () => dispatch(setTenDiscount(false)),
                        disabled: false,
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('modal.ten_percent_selling_price_modal.body.first_content')}</p>
                        <p className="text-center">{t('modal.ten_percent_selling_price_modal.body.second_content')}</p>
                    </>
                }
            />
            <Modal
                size="xl"
                isShow={limitOver.isShow}
                onClose={() => dispatch(setLimitOver())}
                isShowIconClose="true"
                title={t('modal.error_modal.title')}
                btnList={[
                    {
                        name: '閉じる',
                        className: 'btn btn--secondary',
                        onClick: () => dispatch(setLimitOver()),
                        disabled: false,
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('modal.error_modal.body.first_content')}</p>
                        <p className="text-center">{t('modal.error_modal.body.second_content')}</p>
                        <ViewTable heads={prohibitedTable.heads} body={prohibitedTable.body} dataTable={limitOver.items} />
                    </>
                }
            />
            <Modal
                size="xl"
                isShow={prohibited.isShow}
                onClose={() => dispatch(setProhibited())}
                isShowIconClose="true"
                title={t('confirm_registration_price.modal_prohibited.title')}
                btnList={[
                    {
                        name: t('confirm_registration_price.modal_prohibited.button_ok'),
                        className: 'btn--secondary',
                        onClick: () => dispatch(setProhibited()),
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('confirm_registration_price.modal_prohibited.first_content')}</p>
                        <ViewTable heads={prohibitedTable.heads} body={prohibitedTable.body} dataTable={prohibited.items} />
                    </>
                }
            />
            <Modal
                size="xl"
                isShow={nbpbkbn.isShow}
                onClose={() => dispatch(setNbpbkbn())}
                isShowIconClose="true"
                title={t('price_registration.nbpbkbn_modal.title')}
                btnList={[
                    {
                        name: t('price_registration.nbpbkbn_modal.button_ok'),
                        className: 'btn--secondary',
                        onClick: () => dispatch(setNbpbkbn()),
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('price_registration.nbpbkbn_modal.message')}</p>
                        <ViewTable heads={nbpbkbnTable.heads} body={nbpbkbnTable.body} dataTable={nbpbkbn.items} />
                    </>
                }
            />
            <Modal
                size="sm"
                isShow={excelValidation.isShow}
                onClose={() => dispatch(setExcelValidation())}
                isShowIconClose="true"
                title={'入力情報に誤りがあります'}
                btnList={[
                    {
                        name: t('confirm_registration_price.modal_prohibited.button_ok'),
                        className: 'btn--secondary',
                        onClick: () => dispatch(setExcelValidation()),
                    },
                ]}
                children={
                    <ViewTable heads={excelValidationTable.heads} body={excelValidationTable.body} dataTable={excelValidation.items} />
                }
            />
            <Modal
                size="xl"
                isShow={prohibited.isShow}
                onClose={() => dispatch(setProhibited())}
                isShowIconClose="true"
                title={t('confirm_registration_price.modal_prohibited.title')}
                btnList={[
                    {
                        name: t('confirm_registration_price.modal_prohibited.button_ok'),
                        className: 'btn--secondary',
                        onClick: () => dispatch(setProhibited()),
                    },
                ]}
                children={
                    <>
                        <p className="text-center">{t('confirm_registration_price.modal_prohibited.first_content')}</p>
                        <ViewTable heads={prohibitedTable.heads} body={prohibitedTable.body} dataTable={prohibited.items} />
                    </>
                }
            />
            <Modal
                size="sm"
                isShow={deleteConfirm}
                onClose={() => dispatch(setDeleteConfirm(false))}
                isShowIconClose="true"
                title={t('message.info.please.select.data.to.delete.title')}
                btnList={[
                    {
                        name: t('button.delete'),
                        className: 'btn--primary',
                        onClick: () => {
                            dispatch(setIsDeleteContinue(true));
                            dispatch(setDeleteConfirm(false));
                        },
                        disabled: false,
                    },
                    {
                        name: t('button.cancel'),
                        className: 'btn--secondary master-edit-modal__submit',
                        onClick: () => dispatch(setDeleteConfirm(false)),
                        disabled: false,
                    },
                ]}
                children={
                    <>
                        <p class="text-center">{t('message.info.please.select.data.to.delete.title')}</p>
                        <p class="text-center">{t('message.info.please.select.data.to.delete.delete_success')}</p>
                    </>
                }
            />
            <Modal
                size="sm"
                isShow={common.isShow}
                onClose={() => dispatch(setCommon())}
                isShowIconClose="true"
                title={common.title}
                btnList={[
                    {
                        name: '閉じる',
                        className: 'btn btn--secondary',
                        onClick: () => dispatch(setCommon()),
                        disabled: false,
                    },
                ]}
                children={common.body.split('\n').map((item, key) => {
                    return (
                        <p key={key} className="text-center">
                            {item}
                        </p>
                    );
                })}
            />
        </>
    );
};

export default CommonModals;
